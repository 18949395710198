import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Eula = () => (
    <Layout>
        <SEO title="Terms of service"/>
        <section className="contentbox wrap --wideblue">
            <div class="txt_full_box text_left termsbox">
                <h2>
                    End-user license agreement <span>terms of Service</span>
                </h2>

                <p>
                    <i>This Agreement was last modified on 25.05.2021.</i>{" "}
                </p>

                <p>
                    This Agreement (hereinafter the “Agreement”, "User Agreement") was
                    made by and between NAVIAPPS HOLDING LIMITED, a company incorporated
                    and existing under the laws of Cyprus, having its registered address
                    at: Cyprus Clement, 41-43, KLIMENTOS TOWER, Flat 25 1061, Nicosia,
                    Cyprus, registration number HE372488, hereinafter referred to as the
                    "Company" and any person using Mobile app or Website, hereinafter
                    referred to as the "User". This User Agreement describes the terms and
                    conditions which User accept by using Mobile app or Website.
                </p>

                <h3>Terms and Definitions</h3>

                <p>In this User Agreement:</p>

                <p>
                    "We", "our", "company" or "the company" or "us" means NAVIAPPS HOLDING
                    LIMITED.
                </p>

                <p>
                    "User", "you" or "your" means an individual who visits or uses the
                    mobile app or website and accepted the terms of this Agreement in
                    full.
                </p>

                <p>
                    "Website" means the website operated by NAVIAPPS HOLDING LIMITED and
                    available at: www.boatpilot.me and any of its regional or other
                    domains or properties
                </p>

                <p>
                    "Mobile app" means the software and any application iOS, Android, or
                    API named "Boatpilot", operated by NAVIAPPS HOLDING LIMITED, developed
                    specifically for use on small, wireless computing devices, such as
                    smartphones and tablets.
                </p>

                <p>"Account" means the account associated with your email address.</p>

                <p>
                    "Intellectual Property Rights" means any and all intellectual property
                    rights, existing worldwide and the subject matter of such rights,
                    including: (a) patents, copyright, rights in circuit layouts (or
                    similar rights), registered designs, registered and unregistered
                    trademarks, and any right to have confidential information kept
                    confidential; and (b) any application or right to apply for
                    registration of any of the rights referred to in paragraph (a),
                    whether or not such rights are registered or capable of being
                    registered and whether existing under any laws, at common law or in
                    equity.
                </p>

                <h3>General Provisions</h3>
                <p>
                    The company offers Users the opportunity to use its services on the
                    terms and conditions of this User Agreement. This Agreement shall take
                    effect upon the User’s express agreement with its terms in the manner
                    provided below.
                </p>

                <p>
                    This Agreement may be amended by Company without notice, and a new
                    revision of this Agreement shall take effect when posted online at the
                    web address given in this paragraph, unless otherwise stated in the
                    new revision of this Agreement. The current revision of this User
                    Agreement is always available at https://www.boatpilot.me/terms/.
                </p>

                <p>
                    By starting the use of Mobile app or Website /certain functions or
                    going through the registration procedure, the User shall be deemed to
                    have accepted the terms of this Agreement in full without any
                    limitations or exceptions. If the User does not agree to any
                    provisions of this Agreement, the User must not use Mobile app and
                    Website. If Company makes any amendments to this Agreement to which
                    the User does not agree, the User shall cease to use Mobile app and
                    Website.
                </p>

                <h3>License</h3>
                <p>
                    The Company shall under a simple (non-exclusive) license grant to the
                    User a non-transferable right to use the Mobile app and Website in all
                    countries of the world in the following ways: To use the Mobile app as
                    intended and to this end produce and install (run) it on a mobile
                    device(s) of the User. The User may install the Mobile app on an
                    unlimited number of mobile devices. To run the Mobile app for
                    non-commercial purposes (free of charge). The User has the right to
                    use the Website for its direct functional purpose by downloading and
                    viewing the Website on any personal computers and mobile device(s).
                    Cartographic materials, boat traffic database, boat traffic accident
                    along with intellectual property accessed through the Mobile app and
                    Website (hereinafter referred to as the Data) belong to the Company
                    and/or their owners and are only meant for personal non-commercial use
                    by the User as part of the functionalities of the Mobile app and
                    Website. The User is hereby notified of and agrees that the Mobile app
                    and Website will transmit to the Company data of the User’s location
                    and traffic characteristics received from a GPS device, or the User’s
                    approximate location determined by an active cell of the
                    communications service provider whose SIM card is used at that moment
                    in the User’s mobile device, for gathering statistical information on
                    the boat traffic status, and depersonalized voice records.
                </p>

                <p>
                    Terms of Use of Individual Functions of the Mobile app and Website.
                </p>
                <p>
                    The Mobile app and Website provides the following functions to the
                    User:
                </p>

                <p>• Gain free access to updated detail mapping.</p>
                <p>• Search and obtain information about objects on the map.</p>
                <p>
                    • Select the direction of navigation to the objects on the map "in a
                    straight line" without obstacles.
                </p>
                <p>
                    • Storing trip statistics in the User’s personal account, subject to
                    the User’s authorization in the Mobile app and Website and
                    confirmation of the User’s consent to gathering of statistics in the
                    Mobile app and Website.
                </p>
                <p>
                    • Send messages and negotiate with other users of the Mobile app and
                    Website.
                </p>

                <p>
                    • Mobile application can collect background location even when the app is closed</p>

                <p> • Foreground location access is used to display your position, speed, bearing and record you
                    track.</p>
                <p> • Foreground and background location access can be used to used to provide ads and info about
                    nearby services even when the app is not in use.</p>
                <p> • Foreground and background location access is used to record your tracks and alert you when you
                    close to danger alerts when app is closed and not used.</p>
                <p> • Information collection using foreground and background location access is used to collect your
                    route statistic even when the app is not in use.</p>


                <p>
                    NOTE! The Mobile app and Website are not a substitute for navigation
                    devices. The User should closely follow the special equipment on board
                    the vessel and be guided by its data when drawing up the route and
                    following the route. The User shall independently and fully bear all
                    risks and responsibility for the selection of the routes and following
                    the selected routes. The Company does not guarantee the route
                    accuracy, optimality, relevance and/or the compliance of the route
                    with the boat traffic regulations and shall not be responsible for the
                    User’s choice of routes and following them.
                </p>

                <h3>User Registration. User Account.</h3>
                <p>
                    To use Mobile app and Website or certain specific functions of Mobile
                    app and Website, the User shall complete registration to create a
                    unique account. Upon registration the User shall provide valid and
                    complete information requested in the registration form and shall
                    regularly update such information. If the User provides invalid
                    information or Company has reasons to believe that any information
                    provided by the User is incomplete or invalid, Company may at its
                    discretion block or delete the User’s account or deny the User the use
                    Mobile app and Website (or certain functions).
                </p>

                <p>
                    When signing up, the User may upload an image for his/her profile
                    (user icon). Account may contain materials published by the User as
                    part of the use of the Mobile application and the Website: the user's
                    contact details, links to social networking pages, photo, name,
                    characteristics of the vessel. User understands and agrees that
                    Company has the right to store, publish and otherwise use the images
                    and materials provided by the User when using the Mobile app and
                    Website in order to provide services to Users of the Mobile app and
                    Website, as well as to promote and advertise such services. When User
                    uploads or otherwise submits content to the Mobile app and Website,
                    the user grants to Company worldwide a license to use, post, store,
                    reproduce, modify, create derivative works (e.g., as a result of
                    translation, adaptation or other changes Company to make the content
                    better work with the services), communicate, publish, publicly
                    perform, publicly display and distribute such content. The rights
                    granted by this license are for the limited purpose of operating,
                    promoting and improving the Company's services, as well as for
                    developing new ones. This license is retained even if the User stops
                    using the Mobile app and Website (for example, for a business listing
                    added to maps).
                </p>

                <p>
                    Company shall reserve the right to require at any time that the User
                    verify the information provided upon registration and to request
                    supporting documents (personal identification documents in
                    particular); In case the User's information specified in the documents
                    provided by him/her fails to conform to the information provided upon
                    registration or if the information provided upon registration does not
                    make it possible to identify the User, Company shall reserve the right
                    to deny User's access to the User account and to usage of Mobile app
                    and Website. Any personal information of the User contained in the
                    User account shall be stored and processed by Company according to
                    Privacy Policy.
                </p>

                <p>
                    The User shall be responsible for security of the chosen means of
                    access to his/her account and for confidentiality of such means. The
                    User shall be responsible for any actions (and their consequences) in
                    and with Mobile app and Website through the User’s account including
                    voluntary transfer by the User of information required to access the
                    User account to third parties on any conditions (including by
                    contracts or agreements). Any actions in or with Mobile app and
                    Website performed through the User’s account shall be considered done
                    by the User. The User shall promptly inform Company of any instances
                    of unauthorized (not allowed by the User) access to Mobile app and
                    Website through the User’s account and/or any breach (alleged breach)
                    of confidentiality of the chosen means of access to his/her account.
                    For security reasons, the User shall securely log out after completing
                    each session (Log Out button) of operating Mobile app and Website.
                    Company shall not be responsible for any potential loss or damage of
                    information as well as any other consequences appearing as a result of
                    failure by the User to comply with this part of this Agreement.
                    Account use by the User.
                </p>

                <p>
                    The User may not reproduce, duplicate or copy, sell, resell or use for
                    any commercial purposes any parts of Mobile app and Website (including
                    content available to the User through Mobile app and Website) or
                    access to Mobile app and Website, except when authorized by Company or
                    it is directly stated in the User agreement. Account termination.
                    Company may block or delete the User’s account as well as prohibit
                    access through account to certain services and delete any content
                    without giving reasons including in case the User violates the terms
                    of this Agreement. The User may at any time delete its account at
                    Mobile app and Website by contacting the technical support of the
                    Mobile app and Website.
                </p>

                <h3>Terms of service and payment</h3>
                <p>
                    Use of the Mobile app and Website by the User on the terms and
                    conditions of this license for personal non-commercial purposes shall
                    be free. Use of the Mobile app and Website on the terms and conditions
                    and in ways not covered by this license (including commercial use)
                    shall be only possible on the basis of a separate agreement with the
                    Company.
                </p>
                <p>
                    Notwithstanding the foregoing portions of the Mobile app and Website
                    services may be provided for a fee under the terms of this agreement
                    (hereinafter - fee-based feature). All fee-based feature prices and
                    conditions of purchase are listed on each fee-based feature page
                    describing the subscriptions and cart. User agrees to pay the price
                    for the fee-based feature indicated in the pricing schedule set forth
                    on these pages. At the time of account creation or purchase, User
                    needs to provide accurate information regarding account, including a
                    valid debit/credit card, and User must certify that he/she legally
                    able to enter into a valid and binding agreement with Company. Payment
                    of fee-based feature is made by bank transfer.
                </p>

                <p>
                    If User has purchased a subscription online, his/her debit/credit card
                    indicated in the registration process (or on Account page) will be
                    fully charged upon sign up. User agrees that he/she will pay the full
                    price for the whole time upfront. Subscriptions renew automatically
                    until canceled by the User. If the renewal charge fails, the
                    subscription will expire. The company may provide an opportunity to
                    purchase a non-renewable subscription.
                </p>

                <p>
                    Subscription may begin with a free trial period (“Free Trial Period”).
                    User may cancel subscription at any time during and before the end of
                    the Free Trial Period. Upon cancellation of subscription prior to the
                    end of the Free Trial Period, will be refunded for the full amount
                    pre-authorized and/or debited from Users valid payment card. In the
                    absence of a cancellation actioned by User, the subscription fee will
                    automatically apply. Company reserves the right to modify the prices
                    and charges at any time. For subscriptions, which are automatically
                    renewed, such changes will become effective with next billing cycle.
                    For non-renewable subscriptions, such changes will only apply to new
                    subscription terms. User may terminate any subscription under this
                    Agreement. The effective date of such termination will be the end of
                    the current billing period. User shall not be entitled to any refund
                    as a result of the termination of subscription to any fee-based
                    feature under this Agreement.
                </p>

                <h3 id="tax">Taxes</h3>
                <p>
                    User is responsible for paying all applicable sales, use, transfer,
                    value-added, or other taxes and all duties, whether international,
                    national, state, or local, however designated, which result from the
                    purchase of fee-based feature.
                </p>

                <h3>General Usage and Storing Provisions</h3>
                <p>
                    Company may impose restrictions on the use of Mobile app and Website
                    for all Users or certain categories of Users (depending on the User’s
                    location, language of the service, etc.) including:
                    availability/unavailability of certain service functions, period of
                    storing messages and any other content, maximum number of messages
                    that can be sent or received by one registered user, maximum size of
                    an electronic message, maximum number of service uses in a certain
                    period, maximum period of content storage, special parameters of
                    downloadable content, etc. Company may prohibit automatic requests to
                    its servers as well as terminate acceptance of any automatically
                    generated information (for instance, electronic spam).
                </p>

                <p>
                    Company has the right to send information messages to the users. The
                    User agrees to receive advertising messages. The User hereby
                    authorizes Company to notify other Users about new publications being
                    posted by the User, as well as about the User’s actions and other
                    activities performed by the User in the Mobile app and Website.
                    Company may collect opinions and feedback of Users on various matters
                    sending an info message. Collected opinions and feedback can be used
                    to form statistics, which can be used in Mobile app and Website.
                </p>

                <h3>User Content</h3>
                <p>
                    The User shall be responsible for compliance of any content posted by
                    the User with applicable legal requirements including responsibility
                    to third parties in case posting by the User of any content or its
                    subject matter infringes on rights and legitimate interests of third
                    parties including personal non-property rights of authors, any other
                    intellectual property rights of third parties and/or encroaches on
                    other intangible assets.
                </p>

                <p>
                    The User acknowledges and agrees that Company is not under obligation
                    to review any content posted and/or distributed by the User through
                    Mobile app and Website and that Company has the right (but shall not
                    be obliged to) at its discretion to prohibit the User from posting
                    and/or distributing content or may remove any content available
                    through Mobile app and Website. The User acknowledges and agrees that
                    the User shall independently assess any risks related to using content
                    including its reliability, validity or usefulness.
                </p>
                <p>
                    The User acknowledges and agrees that technologies of Mobile app and
                    Website operation may require reproduction (replication) of User
                    content by Company as well as its processing by Company for compliance
                    with technical requirements of Mobile app and Website.
                </p>

                <h3>Terms of Mobile app and Website Use</h3>
                <p>
                    The User shall be responsible to third parties for any actions related
                    to the use of Mobile app and Website including any actions resulting
                    in violation of rights and legitimate interests of third parties as
                    well as compliance with laws upon using the Mobile app and Website.
                </p>

                <p>Upon using Mobile app and Website, the User shall not: </p>
                <p>
                    - modify, decompile, disassemble, decrypt or perform other actions
                    with the object code of the Mobile app in order to gather information
                    on the algorithms implemented in the Mobile app, or create derivative
                    works with the use of the Mobile app, as well as otherwise use (or
                    authorize a third party to use) the Mobile app, any components of the
                    Mobile app, the maps stored by the Mobile app in the User’s mobile
                    device, or other images or other data without a written permission.
                </p>
                <p>
                    - run or distribute the Mobile app for commercial purposes (including
                    for a fee), including as part of a collection of software products
                    without a written permission.
                </p>
                <p>
                    - download, send, transmit or in any other way post and/or distribute
                    any content that is illegal, harmful, slanderous, unethical or
                    violates intellectual property rights, promotes hatred and/or racial,
                    ethnic, sexual, religious or social discrimination or is insulting to
                    any persons and/or organizations;
                </p>
                <p>
                    - violate third party rights including underage people and/or cause
                    harm in any way;
                </p>
                <p>
                    - download, send, transmit or otherwise post and/or distribute content
                    if not authorized to do so by law or contract;
                </p>
                <p>
                    - download, send, transmit or otherwise post and/or distribute
                    unauthorized advertising information, spam;
                </p>
                <p>
                    - download, send, transmit or otherwise post and/or distribute any
                    materials containing viruses or other malicious software;
                </p>
                <p>
                    - collect and store personal information of other persons without
                    proper authorization;
                </p>
                <p>- otherwise violate national legislation, international law.</p>

                <h3>Exclusive Rights to services and Content</h3>
                <p>
                    Any objects available through Mobile app and Website including design
                    elements, text, graphics, illustrations, video, software, databases,
                    music, soundtracks and other objects as well as any content posted at
                    Mobile app and Website are exclusive property of Company, Users and
                    other persons. Any content may be used only within functions offered
                    by a particular Mobile app and Website. No elements of Mobile app and
                    Website and service content may be used in any other way without the
                    Company’s prior consent.
                </p>

                <h3>No Guarantees, Limitation of Liability</h3>
                <p>
                    The User uses Mobile app and Website at his own risk. Mobile app and
                    Website are provided as is. Company accepts not responsibility
                    including for compliance of services with the User's goals;
                </p>

                <p>
                    Company does not guarantee that Mobile app and Website comply/will
                    comply with User's requirements; that Mobile app and Website will be
                    provided without interruptions, promptly, sustainably and without
                    errors; that results received with the use of services are accurate
                    and reliable and may be used for any purposes or in any capacity (for
                    instance, for identification and/or verification of any facts);
                </p>

                <p>
                    Any information and/or materials (including downloadable software,
                    messages, any instructions and guidelines, etc.) which the User
                    accesses through Mobile app and Website may be used by the User at his
                    own risk and the User shall be responsible for any potential
                    consequences of the use of such information and/or materials including
                    any damage to the User or third parties;
                </p>

                <p>
                    Company shall not be liable for any losses resulting from the User
                    using Mobile app and Website or separate parts/ functions Mobile app
                    and Website;
                </p>

                <p>
                    Under any circumstances, Company’s liability is limited to $100 (one
                    hundred us dollars) and shall be imposed in case of guilty actions.
                </p>

                <h3>Other Provisions</h3>
                <p>
                    This Agreement is an agreement between the User and Company regarding
                    the procedure of using Mobile app and Website and shall replace all
                    previous agreements between User and Company.
                </p>

                <p>
                    This Agreement shall be regulated and interpreted according to laws of
                    the Cyprus. Any issues not regulated hereby shall be settled according
                    to Cyprus law. Any disputes arising out of relations regulated by this
                    Agreement shall be settled as prescribed by applicable Cyprus laws
                    according to Cyprus legal standards. In any part of this Agreement,
                    unless otherwise stated, the term “law” shall mean laws of the Cyprus
                    as well as laws of the country of the User’s location.
                </p>

                <p>
                    Nothing in this Agreement shall be interpreted as agency, partnership,
                    mutual activities, employment or any other relations not directly
                    stated in this Agreement.
                </p>

                <p>
                    Failure to comply with any condition or condition of this Agreement as
                    a result of uncontrolled conditions, including but not limited to
                    wars, strikes, floods, government restrictions, power outages, and
                    damage or destruction of any network facilities or services should not
                    be considered a violation of this Agreement. If for any reasons one or
                    several provisions of this Agreement are declared invalid or
                    unenforceable, it shall not affect the validity or enforceability of
                    the remaining provisions hereof.
                </p>

                <p>
                    Failure by Company to act in case of violation by the User or any
                    other users of the provisions of this Agreement shall not deprive
                    Company of the right to take action to protect its interests in the
                    future and shall not be interpreted as waiver by Company of its rights
                    in case of any future similar or identical violations.
                </p>

                <p>
                    This Agreement is made in the English language and in certain cases
                    may be provided to the User for review in a different language. In
                    case of any differences between the English text of this Agreement and
                    the text of this Agreement in any other language, the English text
                    shall prevail.
                </p>

                <p>
                    This agreement and the license granted therein are valid for an
                    unlimited period of time while the Company provides support for the
                    Mobile app and the Website. The Company has the right to terminate
                    this agreement at any time by notifying Users by posting relevant
                    information on the Website.
                </p>
                <p>
                    For correct operation of the Mobile Application and the Website, the
                    Company recommends the use of equipment that meets the following
                    characteristics android 6+, ios 8+.
                </p>
            </div>
        </section>
    </Layout>
);

export default Eula;
